<template>
  <div class="position-relative fill-height">
    <v-fade-transition>
      <v-layout
        v-if="stepSended == false && tokenExpired == false"
        class="fill-width"
        align-center
        justify-center
      >
        <v-card color="transparent" elevation="0" :loading="loading">
          <v-card-title class="pb-0 d-flex mb-3">
            <span>{{ $t("Step 3") }}</span>
          </v-card-title>

          <v-form ref="form" v-model="formValid">
            <v-card-text>
              <v-alert
                class="d-inline-block"
                border="left"
                color="red"
                dark
                dense
                text
              >
                <p class="text-body-1 ma-0">
                  Please review all documents before signing
                </p>
                <v-btn
                  class="mt-4"
                  color="white"
                  elevation="2"
                  depressed
                  rounded
                  light
                  @click="showDocumentsStep3"
                >
                  Show documents
                </v-btn>
              </v-alert>

              <v-row no-gutters>
                <v-col>
                  <v-text-field
                    v-model="fields.surgeonName"
                    :rules="[rules.required]"
                    label="Surgeon Name"
                    outlined
                    dense
                    @input="onInput"
                  />
                </v-col>
                <v-col class="ml-2">
                  <v-text-field
                    v-model="fields.preaACname"
                    :rules="[rules.required]"
                    label="Pre-Admission Coordinator Name"
                    outlined
                    dense
                    @input="onInput"
                  />
                </v-col>
              </v-row>

              <v-row no-gutters>
                <v-col>
                  <div class="text-body-2">Bill To</div>
                  <v-radio-group v-model="billTo" mandatory>
                    <v-radio class="mt-0" :label="getpatientN" />
                    <v-radio class="mt-0" label="Insurance" />
                    <v-radio class="mt-0" label="Doctor" />
                  </v-radio-group>
                </v-col>
                <v-col class="ml-2">
                  <div class="text-body-2">Priority</div>
                  <v-radio-group v-model="priority" mandatory>
                    <v-radio class="mt-0" label="STAT" />
                    <v-radio class="mt-0" label="Routine" />
                  </v-radio-group>
                </v-col>
              </v-row>
              <ma-date-picker
                v-model="fields.toBePerformedonAfter"
                label="To be performedon after"
                future
              />

              <v-textarea
                v-model="fields.procedure"
                label="Procedure"
                :rules="[rules.required]"
                rows="2"
                outlined
                dense
                @input="onInput"
              />
              <div class="text-body-2">Anesthesia</div>
              <v-radio-group v-model="anesthesia" mandatory>
                <v-layout align-center>
                  <v-radio class="my-0 mr-2" label="General" />
                  <v-radio class="mt-0" label="Local" />
                </v-layout>
              </v-radio-group>

              <p class="text-body-2">
                Pre-operative consultation medical clearance request:
              </p>
              <v-layout column>
                <p class="mb-0 text-body-2 font-weight-bold text--secondary">
                  Primary doctor
                </p>
                <v-layout column>
                  <yes-not-selection
                    v-model="fields.pcDoctorMedical"
                    class="pa-1 cursor-pointer rounded"
                    description="Medical Clearance Letter"
                  />
                  <yes-not-selection
                    v-model="fields.pcDoctorPhysical"
                    class="pa-1 cursor-pointer rounded"
                    description="Physical Exam"
                  />
                  <yes-not-selection
                    v-model="fields.pcDoctorOficeNotes"
                    class="pa-1 cursor-pointer rounded"
                    description="Office Notes (Hx)"
                  />
                </v-layout>
              </v-layout>
              <v-layout column>
                <v-text-field
                  v-model="fields.specialist"
                  label="Specialist"
                  :rules="[rules.required]"
                  class="mt-3"
                  outlined
                  dense
                  @input="onInput"
                />
                <v-layout column>
                  <yes-not-selection
                    v-model="fields.specialistMedical"
                    class="pa-1 cursor-pointer rounded"
                    description="Medical Clearance Letter"
                  />
                  <yes-not-selection
                    v-model="fields.specialistPExam"
                    class="pa-1 cursor-pointer rounded"
                    description="Physical Exam"
                  />
                  <yes-not-selection
                    v-model="fields.specialistOfficeNotes"
                    class="pa-1 cursor-pointer rounded"
                    :description="'Office Notes (' + getpatientN + 'Hx)'"
                  />
                </v-layout>
              </v-layout>

              <p class="text-body-2 my-2 font-weight-bold text--secondary">
                Labs
              </p>
              <v-row no-gutters>
                <v-col class="d-flex flex-column">
                  <yes-not-selection
                    v-model="fields.cbc"
                    class="pa-1 cursor-pointer rounded"
                    description="CBC w/Diff"
                  />
                  <yes-not-selection
                    v-model="fields.cmp"
                    class="pa-1 cursor-pointer rounded"
                    description="CMP"
                  />
                  <yes-not-selection
                    v-model="fields.hepatitisPanel"
                    class="pa-1 cursor-pointer rounded"
                    description="Hepatitis Panel"
                  />
                  <yes-not-selection
                    v-model="fields.pTInr"
                    class="pa-1 cursor-pointer rounded"
                    description="PT/INR"
                  />
                  <yes-not-selection
                    v-model="fields.ptt"
                    class="pa-1 cursor-pointer rounded"
                    description="PTT"
                  />
                  <yes-not-selection
                    v-model="fields.hcgQualSerum"
                    class="pa-1 cursor-pointer rounded"
                    description="HCG(Qual) Serum"
                  />
                </v-col>
                <v-col class="d-flex flex-column">
                  <yes-not-selection
                    v-model="fields.hgbA1c"
                    class="pa-1 cursor-pointer rounded"
                    description="HgbA1C"
                  />
                  <yes-not-selection
                    v-model="fields.freeT3"
                    class="pa-1 cursor-pointer rounded"
                    description="Free T3"
                  />
                  <yes-not-selection
                    v-model="fields.freeT4"
                    class="pa-1 cursor-pointer rounded"
                    description="Free T4"
                  />
                  <yes-not-selection
                    v-model="fields.tsh"
                    class="pa-1 cursor-pointer rounded"
                    description="TSH"
                  />
                  <yes-not-selection
                    v-model="fields.niconiteTU"
                    class="pa-1 cursor-pointer rounded"
                    description="Nicotine Test Urine"
                  />
                </v-col>
              </v-row>

              <yes-not-selection
                v-model="fields.completeUri"
                class="pa-1 cursor-pointer rounded"
                description="Complete Urinalysis w/Reflex to Culture"
              />
              <yes-not-selection
                v-model="fields.hivAA"
                class="pa-1 cursor-pointer rounded"
                description="HIV-1/2 Antigen and Antibodies, Fourth Generation, With Reflexes"
              />
              <yes-not-selection
                v-model="fields.covid19"
                class="pa-1 cursor-pointer rounded"
                description="Covid-19 PCR Swab Test (TEST MUST BE PERFORMED 2 WEEKS BEFORE SURGERY)"
              />
              <small class="text--secondary d-block ml-5">
                ***Covid Test performed before the 2 weeks will not be
                accepted***
              </small>

              <v-layout align-center class="mt-3">
                <v-checkbox
                  slot="prepend"
                  v-model="fields.dcOther"
                  class="mt-0"
                  hide-details
                />
                <v-text-field
                  v-model="fields.dcOtherDescription"
                  :disabled="!fields.dcOther"
                  label="Other"
                  hide-details
                  outlined
                  dense
                  @input="onInput"
                />
              </v-layout>

              <p class="text-body-2 mt-4">Diagnosis Studies</p>
              <v-row no-gutters>
                <v-col>
                  <yes-not-selection
                    v-model="fields._12Lead"
                    class="pa-1 cursor-pointer rounded"
                    description="12 LEAD EKG"
                  />
                </v-col>
                <v-col class="ml-2">
                  <yes-not-selection
                    v-model="fields.bbUltrasound"
                    class="pa-1 cursor-pointer rounded"
                    description="Bilateral Breast Ultrasound"
                  />
                </v-col>
              </v-row>
              <v-row no-gutters>
                <v-col>
                  <yes-not-selection
                    v-model="fields.chestXray"
                    class="pa-1 cursor-pointer rounded"
                    description="Chest X-ray"
                  />
                </v-col>
                <v-col class="ml-2">
                  <yes-not-selection
                    v-model="fields.bbMamogram"
                    class="pa-1 cursor-pointer rounded"
                    description="Bilateral Breast Mammogram"
                  />
                </v-col>
              </v-row>
              <v-layout align-center class="mt-3">
                <v-checkbox
                  slot="prepend"
                  v-model="fields.labsOther"
                  class="mt-0"
                  hide-details
                />
                <v-text-field
                  v-model="fields.labsOtherDescription"
                  :disabled="!fields.labsOther"
                  label="Other"
                  hide-details
                  outlined
                  dense
                />
              </v-layout>

              <small class="text--secondary d-flex">
                *** Fax Results STAT to 1-305-262-3995 ***
              </small>
              <v-text-field
                v-model="fields.npiNo"
                :rules="[rules.required]"
                class="mt-3"
                label="NPI#"
                outlined
                dense
                @input="onInput"
              />

              <v-layout class="mt-4" justify-center align-center column>
                <v-alert border="left" color="red" width="500" dark dense text>
                  <p class="text-body-2 ma-0 font-weight-bold">
                    My signature below indicates that I am approving the above
                    related charges and that I way not cancel the autorization
                    after my approval.
                  </p>
                </v-alert>
                <drawing-board
                  v-model="signature"
                  :attrs="{
                    width: $vuetify.breakpoint.xs ? '325px' : '500px',
                    height: '270px',
                  }"
                  :style="
                    signature.size == 0
                      ? {
                          border: 'solid var(--v-error-base) !important',
                        }
                      : {}
                  "
                />
              </v-layout>

              <p class="text-body-1 my-3">
                The data from this form will be used to be inserted in the
                documents sent by mail.
              </p>
              <errors
                v-model="showErrors"
                class="mt-3"
                :message="errorMessage"
              />
            </v-card-text>
          </v-form>

          <v-divider />
          <v-card-actions>
            <v-spacer />
            <v-btn
              color="primary"
              width="120px"
              :disabled="loading || !canSubmit"
              :loading="loading"
              depressed
              rounded
              @click="sendData"
            >
              Send
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-layout>
    </v-fade-transition>

    <v-fade-transition>
      <end-of-step v-if="stepSended">
        <v-layout class="mb-6" justify-center align-center column>
          <div class="text-h5 text-center font-weight-light mb-3">
            You can review all documents by pressing in the button below
          </div>
          <v-btn color="primary" depressed rounded @click="showAllDocuments">
            Show documents
          </v-btn>
        </v-layout>
      </end-of-step>
    </v-fade-transition>

    <document-viewer
      v-if="documents.length > 0"
      v-model="showDocuments"
      :documents="currentDocuments"
    />

    <v-fade-transition>
      <token-expired v-if="tokenExpired" />
    </v-fade-transition>
  </div>
</template>

<script>
/* eslint-disable @typescript-eslint/no-explicit-any */
import Vue from "vue";
import DrawingBoard from "@/components/DrawingBoard.vue";
function EndOfStep() {
  return import(/* webpackChunkName: "end-of-step" */ "./EndOfStep.vue");
}

import { getAPI } from "@/api/axios-base";
import rules from "@/components/account/rules";
import stepFielfdMixin from "./stepFielfdMixin";
import {
  notificationError,
  notifyError,
  notifyInfo,
} from "@/components/Notification";
import Errors from "./Errors.vue";
import { AxiosError } from "axios";
import { apiBaseUrl } from "@/enviorment";
import DocumentViewer from "./DocumentViewer.vue";
import TokenExpired from "./TokenExpired.vue";
import YesNotSelection from "@/components/YesNotSelection.vue";
import MaDatePicker from "@/components/MaDatePicker/MaDatePicker.vue";
import { mapGetters } from "vuex";

export default Vue.extend({
  name: "step3",
  mixins: [stepFielfdMixin],
  components: {
    DrawingBoard,
    Errors,
    EndOfStep,
    DocumentViewer,
    TokenExpired,
    YesNotSelection,
    MaDatePicker,
  },
  data() {
    return {
      formValid: false,
      stepSended: false,
      loading: false,
      rules,
      billTo: 0,
      priority: 0,
      anesthesia: 0,
      toBePerformedonAfterMenu: false,
      signature: new Blob(),
      showErrors: false,
      errorMessage: "",
      showDocuments: false,
      fields: {
        _12Lead: false, //
        anesthesiaG: false, //
        anesthesiaL: false, //
        bbMamogram: false, //
        bbUltrasound: false, //
        btDoctor: false, //
        btInsurance: false, //
        btPatient: false, //
        cbc: false, //
        chestXray: false, //
        cmp: false, //
        completeUri: false, //
        covid19: false, //
        dcOther: false, //
        dcOtherDescription: "", //
        // diagnosis: "", //
        freeT3: false, //
        freeT4: false, //
        hcgQualSerum: false, //
        hepatitisPanel: false, //
        hgbA1c: false, //
        hivAA: false, //
        labsOther: false, //
        labsOtherDescription: "", //
        niconiteTU: false, //
        // notes: "", //
        npiNo: "", //
        pcDoctorMedical: false, //
        pcDoctorOficeNotes: false, //
        pcDoctorPhysical: false, //
        preaACname: "", //
        procedure: "", //
        pRoutine: false, //
        pStat: false, //
        pTInr: false, //
        ptt: false, //
        specialist: "", //
        specialistMedical: false, //
        specialistOfficeNotes: false, //
        specialistPExam: false, //
        surgeonName: "", //
        toBePerformedonAfter: "", //
        tsh: false, //
        token: "",
      },
      currentDocuments: [],
      tokenExpired: false,
    };
  },
  computed: {
    ...mapGetters(["getpatientN"]),
    canSubmit() {
      const haveSignature = this.signature.size > 0;

      return haveSignature && this.formValid;
    },
    documents() {
      const token = this.$route.params.uuid;

      return [
        {
          title: "Pre-Surgery Order",
          url: `${apiBaseUrl}/patient/getPdfJonesRx/${token}`,
        },
      ];
    },
    documentsStep1() {
      const token = this.$route.params.uuid;

      return [
        {
          title: this.getpatientN + " Contract",
          url: `${apiBaseUrl}/patient/getPdfDorisContracts/${token}`,
        },
        {
          title: "Card contract",
          url: `${apiBaseUrl}/patient/getPdfCardContracts/${token}`,
        },
      ];
    },
    documentsStep2() {
      const token = this.$route.params.uuid;

      return [
        {
          title: "Wellcome package",
          url: `${apiBaseUrl}/patient/getWelcome_PackagePdf/${token}`,
        },
        {
          title: "Acknowledgement",
          url: `${apiBaseUrl}/patient/getAcknowledgementPdf/${token}`,
        },
        {
          title: "Medical information",
          url: `${apiBaseUrl}/patient/getPdfMedicalInformation/${token}`,
        },
        {
          title: "Video and photo autorization",
          url: `${apiBaseUrl}/patient/getPdfVideoPhoto/${token}`,
        },
        {
          title: this.getpatientN + " rights",
          url: `${apiBaseUrl}/patient/getPdfPatientRight/${token}`,
        },
      ];
    },
  },
  watch: {
    billTo(value) {
      switch (value) {
        case 0:
          this.fields.btPatient = true;
          this.fields.btInsurance = false;
          this.fields.btDoctor = false;
          break;
        case 1:
          this.fields.btPatient = false;
          this.fields.btInsurance = true;
          this.fields.btDoctor = false;
          break;
        case 2:
          this.fields.btPatient = false;
          this.fields.btInsurance = false;
          this.fields.btDoctor = true;
          break;
      }
    },
    priority(value) {
      switch (value) {
        case 0:
          this.fields.pStat = true;
          this.fields.pRoutine = false;
          break;
        case 1:
          this.fields.pStat = false;
          this.fields.pRoutine = true;
          break;
      }
    },
    anesthesia(value) {
      switch (value) {
        case 0:
          this.fields.anesthesiaG = true;
          this.fields.anesthesiaL = false;
          break;
        case 1:
          this.fields.anesthesiaG = false;
          this.fields.anesthesiaL = true;
          break;
      }
    },
    toBePerformedonAfterMenu(val) {
      try {
        val &&
          setTimeout(
            () =>
              (this.$refs.toBePerformedonAfterDatepicker.activePicker = "YEAR")
          );
      } catch (error) {
        /*  */
      }
    },
  },
  mounted() {
    this.checkToken();
  },
  methods: {
    async sendData() {
      const formData = new FormData();
      const { token } = this.fields;

      this.loading = true;
      try {
        await getAPI.post("/patient/signStep3", this.fields);
        // Send signature
        formData.append("firma", this.signature, `signature-${token}`);
        await getAPI({
          method: "post",
          url: `/patient/uploadPatienSign/${token}/STEP_3`,
          data: formData,
          headers: { "Content-Type": "multipart/form-data" },
        });
        notifyInfo("Your request has been sended for revision");
        this.stepSended = true;
        this.loading = false;
      } catch (error) {
        let mess = error.response.data.message;

        if (mess.includes("[")) {
          mess = mess.replace("[", "");
          mess = mess.replace("]", "");
        }
        notifyError(error.response.data, `An error occurred: ${mess}`);

        this.loading = false;
      }
    },
    saveToBePerformedonAfter(date) {
      this.$refs.toBePerformedonAfterMenu.save(date);
    },
    showAllDocuments() {
      this.currentDocuments = [];
      setTimeout(() => {
        this.currentDocuments = [
          ...this.documentsStep1,
          ...this.documentsStep2,
          ...this.documents,
        ];
        this.showDocuments = true;
      }, 1000);
    },
    showDocumentsStep3() {
      this.currentDocuments = this.documents;
      this.showDocuments = true;
    },
    checkToken() {
      getAPI("/patient/checkToken/" + this.$route.params.uuid)
        .then(() => {
          this.fields.token = this.$route.params.uuid;
          this.$refs.form.validate();
        })
        .catch((error) => {
          if (error.response?.status == 404) {
            this.tokenExpired = true;
          }
        });
    },
  },
});
</script>
